import React from 'react';
import {Layout} from "@layout/Layout/Layout";
import {Link} from 'gatsby'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

import ByStates from '@elegantstack/gatsby-starter-flexiblog-agency/src/components/ByStates';

const State = (props) => {
    return (
        <Layout {...props}>
            <Seo title='Compliance by States' description='Tailored solutions for your business' />
            {/* <Divider /> */}
            <ByStates/>
        </Layout>
    );
};

export default State;