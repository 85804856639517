import React from 'react';
import {Link} from 'gatsby'

import VectorShape11 from '../WeServe/vector-shape11.png'

const Raionebi = () => {
    return (
        <section className="about-area ptb-10">

        {/* <div className="circle-shape1">
            <img src={shape1} alt="banner" />
        </div> */}

        <div className="container">
            <div className="about-inner-area">
            <h1>Regulations by state</h1>
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="about-text">
                            
                            {/* <p>Real innovations.</p> */}
                            
                            <ul className="features-list">
                            <li><i className="flaticon-right"> </i>
                                    <Link to="/al-state-regulations-small-business-requirements" className="learn-more-btn">
                                        Alabama (AL)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ak-state-regulations-small-business-requirements" className="learn-more-btn">
                                        Alaska (AK)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/az-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Arizona (AZ)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ar-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Arkansas (AR)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ca-state-regulations-small-business-requirements" className="learn-more-btn">
                                    California (CA)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/co-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Colorado (CO)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ct-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Connecticut (CT)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/de-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Delaware (DE)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/fl-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Florida (FL) 
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ga-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Georgia (GA)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/hi-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Hawaii (HI)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/id-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Idaho (ID)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/il-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Illinois (IL) 
                                    </Link>
                                </li>



                            </ul>


                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="about-text">
                            {/* <h3>Our Mission</h3>
                            <p>Real innovations and a positive customer experience are the heart of successful communication.</p> */}
                            
                            <ul className="features-list">

                                <li><i className="flaticon-right"> </i>
                                    <Link to="/in-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Indiana (IN)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ia-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Iowa (IA)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ks-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Kansas (KS)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ky-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Kentucky (KY)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/la-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Louisiana (LA)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/me-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Maine (ME)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/md-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Maryland (MD)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ma-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Massachusetts (MA)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/mi-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Michigan (MI)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/mn-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Minnesota (MN)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ms-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Mississippi (MS)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/mo-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Missouri (MO)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/mt-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Montana (MT)
                                    </Link>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div className="about-text">
                            {/* <h3>Who we are</h3>
                            <p>Real innovations and a positive customer experience are the heart of successful communication.</p> */}
                            
                            <ul className="features-list">


                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ne-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Nebraska (NE)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/nv-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Nevada (NV)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/nh-state-regulations-small-business-requirements" className="learn-more-btn">
                                    New Hampshire (NH)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/nj-state-regulations-small-business-requirements" className="learn-more-btn">
                                    New Jersey (NJ) 
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/nm-state-regulations-small-business-requirements" className="learn-more-btn">
                                    New Mexico (NM)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/nc-state-regulations-small-business-requirements" className="learn-more-btn">
                                    North Carolina (NC)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/nd-state-regulations-small-business-requirements" className="learn-more-btn">
                                    North Dakota (ND)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ny-state-regulations-small-business-requirements" className="learn-more-btn">
                                    New York (NY)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/oh-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Ohio (OH)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ok-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Oklahoma (OK)
                                    </Link>
                                </li>

                                <li><i className="flaticon-right"> </i>
                                    <Link to="/or-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Oregon (OR)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/pa-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Pennsylvania (PA)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/pr-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Puerto Rico (PR)
                                    </Link>
                                </li>


                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div className="about-text">
                            {/* <h3>Who we are</h3>
                            <p>Real innovations and a positive customer experience are the heart of successful communication.</p> */}
                            
                            <ul className="features-list">

                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ri-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Rhode Island (RI)
                                    </Link>
                                </li>

                                <li><i className="flaticon-right"> </i>
                                    <Link to="/sc-state-regulations-small-business-requirements" className="learn-more-btn">
                                    South Carolina (SC)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/sd-state-regulations-small-business-requirements" className="learn-more-btn">
                                    South Dakota (SD)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/tn-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Tennessee (TN)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/tx-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Texas (TX)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ut-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Utah (UT)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/vt-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Vermont (VT)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/va-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Virginia (VA)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/wa-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Washington (WA) 
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/wv-state-regulations-small-business-requirements" className="learn-more-btn">
                                    West Virginia (WV)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/wi-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Wisconsin (WI)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/wy-state-regulations-small-business-requirements" className="learn-more-btn">
                                    Wyoming (WY)
                                    </Link>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* <div className="circle-shape1">
            <img src={shape1} alt="banner" />
        </div> */}
    </section>
    )
}

export default Raionebi;